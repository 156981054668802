<template>
  <base-select
    placeholder="Display"
    :btn-props="{
      size: 'px-2.5 py-1.5',
      class: 'text-sm'
    }"
    :show-selected-option-icon="false"
    disable-type-ahead
  >
    <template
      #selectedOption="{
        placeholder,
        selected,
        anyOptionSelected
      }"
    >
      <span v-if="anyOptionSelected">
        {{ placeholder }}
        <span class="font-semibold">
          {{ selected }}
        </span>
      </span>
      <span v-else>
        {{ placeholder }}
      </span>
    </template>
    <template #dropdownIcon>
      <base-svg
        v-bind="dropDownIconProps"
        src="icons/arrowDownWide.svg"
        class="ml-1.5 float-right h-4 w-3"
        :svg-attributes="{
          class: 'w-3 h-4'
        }"
        tag="span"
      />
    </template>
  </base-select>
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
    name: 'DisplayLimitSelector',

    components: {
        BaseSelect: defineAsyncComponent(() => import('@/components/generic-components/BaseSelect.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue'))
    },

    props: {
        dropDownIconProps: {
            type: Object,
            default: () => {},
            description: 'dropdown icon props(use for overiding default attributes/props values for base-svg)'
        }
    }
};
</script>
